import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import servicesList from "../components/ServicePageComponents/ServiceHelper";

const ServicesPage = () => {
  const [activeCat, setActiveCat] = useState<string>("All");

  const filteredServices = servicesList.filter((service) => {
    if (activeCat === "All") {
      return service;
    }
    return service.category === activeCat;
  });

  return (
    <Container
      fluid
      style={{
        backgroundColor: "#121212", // Black background
        minHeight: "100vh", // Ensure it covers the viewport
        padding: "2rem",
        color: "#ffffff", // Light text color
      }}
    >
      <h1 className="display-2 my-3 text-center fw-bold">Services</h1>

      <Row>
        {filteredServices.map((service, index) => {
          return (
            <Col key={index} md={6} lg={4} xs={12}>
              <Card
                className="mb-4"
                style={{
                  minHeight: "700px",
                  backgroundColor: "#1e1e1e", // Dark card background
                  color: "#ffffff", // Light text
                  border: "1px solid #333", // Subtle border for contrast
                }}
              >
                <Card.Img
                  variant="top"
                  src={service.thumbnail}
                  style={{
                    height: "300px",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center top",
                  }}
                />
                <Card.Body>
                  <Card.Title className="fw-bold">{service.title}</Card.Title>
                  <Card.Text>{service.shortDescription}</Card.Text>
                  <Link to={`/services/${service.title}`}>
                    <Button variant="outline-light">Details</Button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ServicesPage;
