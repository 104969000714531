import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoShield } from "react-icons/go";
import { GiCctvCamera } from "react-icons/gi";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./LandingAbout.css";

const LandingPageAboutUs = () => {
  return (
    <div className="landing-about dark-mode">
      <Container>
        <Row className="align-items-center landing-row">
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  delay={100}
                  duration={1}
                  animateOnce={true}
                >
                  <div>
                    <GoShield size={60} className="icon-color" />
                    <span className="highlight-color fs-3 fw-bold mt-5"> 6+ </span>
                  </div>
                  <p className="fs-5 fw-bold text-color mt-2">
                    ANNÉES D'EXPÉRIENCE
                  </p>
                  <hr className="landing-line" />
                </AnimationOnScroll>
              </Col>
              <Col>
                <AnimationOnScroll
                  animateIn="animate__fadeInUp"
                  delay={350}
                  duration={1}
                  animateOnce={true}
                >
                  <div>
                    <GiCctvCamera size={60} className="icon-color" />
                    <span className="highlight-color fs-3 fw-bold mt-5"> 50+ </span>
                  </div>
                  <p className="fs-5 fw-bold text-color mt-2">
                    CLIENTS SATISFAITS
                  </p>
                  <hr className="landing-line" />
                </AnimationOnScroll>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <AnimationOnScroll
              animateIn="animate__fadeInUp"
              delay={550}
              duration={1}
              animateOnce={true}
            >
              <div className="text-color">
                <h3 className="fs-2 fw-bold mb-4">Bienvenue dans notre société</h3>
                <p>
                  Société Aigle Sécurité est une entreprise de sécurité
                  professionnelle et innovante de taille moyenne, forte d'une
                  expérience avérée. Nous fournissons des agents de sécurité de
                  haut calibre, formés de manière approfondie à l'aide de
                  techniques pratiques basées sur des stratégies modernes de
                  lutte contre la criminalité.
                </p>
              </div>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LandingPageAboutUs;
