export const statements = [
  `Le Service des ressources humaines de la SAS veille å ce que tout le personnel
passe par un processus rigoureux de sélection.
Le personnel dispose d'équipements et d'uniformes adéquats ainsi qu'une bonne
formation pour mener å bien et d'une maniére professionnelle les missions de
sécurité et de gardiennage qui lui sont confiées.
Nos agents sont en mesure de fournir les missions suivantes: Protection, contröle
d'accés, inspection de véhicules, utilisation de portes de contröle. Patrouilles,
intervention rapide, détection et tutte contre l'incendie etc.`,

  `La SAS met à la disposition de ses agents le matériel adéquat pour la bonne exécution du service de sécu-
rité comme sifflet, miroir d'inspection des véhicules, radios talkie-walkie, bâton de garde, detecteur de
métaux, station GSM, lampes de poche.`,

  `Pour ta SAS, la formation a toujours été considérée comme ta pierre angulaire de l'assurance qualité d'
nos services.
Nous sommes une entreprise qui fournit des services de sécurité et nous ne prétendons pas avoir tous le
accessoires de connaissances pour tes services que nous offrons, c'est pourquoi nous avons créé notr.
centre de formation (Centre EAGLE de Formation) agréeet spécialisé dans la sécurité privée et les activi
tés connexes pour la formation de nos agents en premier lieu, les agents et tes cadres des établissement
publiques et organisations privées en deuxième lieu
Nos formations couvrent tes matières suivantes :
•Formation des agents de gardiennage
•Secourisme
•Lutte contre l'incendie
•Sûreté et sécurité des établissements au profit des cadres
.Formation QHSE
•Certificat international en Anglais
•Certificat international en Conduite défensive
Et plus d'autres formations sur mesure pour satisfaire les besoins de nos
clients.`,
];
