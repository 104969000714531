import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./AboutHero.css";

const AboutHero = () => {
  return (
    <div>
      <Container>
        <Row className="py-5">
          <Col
            md={{ span: 6, order: "first" }}
            xs={{ span: 12, order: "last" }}
          >
            <div className="about-us-text">
              <h1 className="fs-3 fw-bold my-3">A Propos Nous</h1>
              <p className="fs-6">
              La Société AIGLE DE SECURITE (SAS) est une société spécialisée dans le sec-
teur de la süreté privée et des entreprises. Elle a été créée conformément å la
loi numéro 81-2002 du 3 Aout 2002 relative å l'exercice des activités privées
de contröle, de transport de fonds et des métaux précieux et de protection
physique des personnes.
Elle est Sise å Tataouin et dispose d'une agence å Tunis. Elle est dirigée par un
ancien officier supérieur de l'Armée. Elle dispose d'un cadre de personnel
qualifié et expérimenté .
              </p>
            </div>
          </Col>
          <Col className="text-center" md={6} xs={12}>
            <img
              src="https://images.unsplash.com/photo-1576807100081-6e12175343c6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fHNlY3VyaXR5JTIwZ3VhcmR8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
              alt="about-us"
              className="about-us-img"
            />
          </Col>
        </Row>
        <Row className="py-5">
          <Col md={6} xs={12} className="text-center">
            <img
              src="https://images.unsplash.com/photo-1557081999-aed1863cacb1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODl8fGFybWVkJTIwZ3VhcmRzfGVufDB8MHwwfHw%3D&auto=format&fit=crop&w=700&q=60"
              alt="about-us"
              className="about-us-img "
            />
          </Col>
          <Col md={6} xs={12}>
            <div className="about-us-text">
              <h1 className="fs-3 fw-bold my-3">Notre Procédure</h1>
              <p className="fs-6">
              La SAS effectue des audits et
des études pour répondre aux
besoins des entreprises en
termes de sécurité et la mise en
place de dispositifs de protec-
tion adéquats
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutHero;
