import React, { useState } from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";
import FooterContactInfo from "./FooterContactInfo";
import FooterForm from "./FooterForm";
import { BsFacebook } from "react-icons/bs";

const Footer = () => {
  const [contact, setContact] = useState(true);
  const [serviceFrom, setServiceForm] = useState(false);

  const contactClicked = () => {
    setContact(true);
    setServiceForm(false);
  };

  const serviceClicked = () => {
    setContact(false);
    setServiceForm(true);
  };

  return (
    <div className="footer py-5 text-center">
      <Container>
        <div className="footer-text">
          <h3 className="fs-4 font-monospace">
            Services de Sécurité Professionnels
          </h3>
          <span className="fs-1">Contactez-nous</span>
          <div className="square my-3 mb-5" />

          <span
            onClick={contactClicked}
            className={
              contact ? "footer-btn me-3 active" : "footer-btn me-3 not-active"
            }
          >
            Informations de Contact
          </span>
          <span
            onClick={serviceClicked}
            className={
              serviceFrom
                ? "footer-btn me-3 active"
                : "footer-btn me-3 not-active"
            }
          >
            Demande de Service
          </span>

          <p className="footer-msg mb-5">
            Merci d'avoir choisi Aigle Sécurité pour vos besoins en sécurité.
            Votre sécurité est notre priorité absolue.
          </p>

          {contact && <FooterContactInfo />}
          {serviceFrom && <FooterForm />}

          <div className="text-muted mt-5">
            <a
              href="https://www.facebook.com/aiglesecuritetunisie/"
              className="facebook-icon"
            >
              <BsFacebook />
            </a>
            <p className="fs-6 mt-3">© 2024 Foudhaili Holding</p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
