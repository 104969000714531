import React from "react";
import Map from "../Map";

const ContactUsInfo = () => {
  // Add styles for the main container and page background
  const styles = {
    page: {
      backgroundColor: "black",
      color: "white",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    container: {
      backgroundColor: "#1a1a1a",
      color: "white",
      padding: "30px",
      borderRadius: "8px",
      maxWidth: "600px",
      width: "100%",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
    },
    heading: {
      color: "yellow",
     
    },
    infoSection: {
      marginBottom: "20px",
    },
    paragraph: {
      margin: "5px 0",
      lineHeight: "1.6",
      fontSize: "1rem",
    },
    mapContainer: {
      marginTop: "20px",
      borderRadius: "8px",
      overflow: "hidden",
    },
  };

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h2 style={styles.heading} className="fw-bold fs-3 mb-2">
         Nos Informations : 
        </h2>
        <div className="my-4">
        <p style={styles.paragraph}>
            <strong>Address:</strong> Route Remada Km7 Tataouine Tunisie <br></br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Route Mateur Km7 Manouba Tunisie
          </p>
          <p style={styles.paragraph}>
            <strong>Email:</strong> contact@aiglesecurite.tn
          </p>
     
          <p style={styles.paragraph}>
            <strong>Email:</strong> comercial@aigledesecurite.tn
          </p>
          <p style={styles.paragraph}>
            <strong>Tel:</strong> +216 75 851 990
          </p>
          <p style={styles.paragraph}>
            <strong>Tel:</strong> +216 71 601 401
          </p>
          <p style={styles.paragraph}>
            <strong>Mobile:</strong> +216 99 952 000
          </p>
          <p style={styles.paragraph}>
            <strong>Mobile:</strong> +216 98 750 850
          </p>
        </div>

        {/* Adjust the Map center to the company's actual coordinates if known */}
        <Map center={{ lat: 36.835, lng: 10.233 }} zoom={16} />
      </div>
    </div>
  );
};

export default ContactUsInfo;
