import React from "react";
import JobApplicationForm from "../components/CareerPageComponents/JobApplicationForm";
import { Container } from "react-bootstrap";
import "../components/CareerPageComponents/CareerPage.css";

const CareersPage = () => {
  return (
    <Container className="careers-page py-5">
      <h1 className="welcome-header text-center mb-5">
        Rejoignez notre entreprise leader en sécurité et profitez de nombreux avantages
      </h1>
      <div className="benefits-container mb-5">
        <ul className="benefits-list">
          <li>Salaire compétitif</li>
          <li>Emploi permanent</li>
          <li>Formation interne</li>
          <li>Congés maladie payés</li>
          <li>Plan de santé et assurance vie</li>
          <li>Indemnisation des accidents du travail</li>
          <li>Plan de retraite</li>
          <li>Congés annuels et congés occasionnels</li>
          <li>Prime annuelle (partage des bénéfices)</li>
          <li>Double rémunération les jours fériés</li>
          <li>Assistance pour le développement personnel</li>
          <li>Et bien d'autres avantages</li>
        </ul>
        <p>
          Exigences : Vous devez être en bonne condition physique, citoyen de Trinité-et-Tobago, âgé de 18 à 50 ans. 
          Un permis de conduire valide (boîte manuelle) avec 2 à 3 ans d'expérience sera un atout. 
          Des vérifications internes d'antécédents seront effectuées. Les candidats non retenus seront informés. 
          Une formation de classe mondiale est incluse.
        </p>
      </div>
      <JobApplicationForm />
    </Container>
  );
};

export default CareersPage;
