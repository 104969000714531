import React from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import "./Appointments.css";

const Appointment = () => {
  return (
    <div className="appointments">
      <Container>
        <h3 className="oxygen display-5 text-light">
          RÉSERVEZ VOTRE
          <br />{" "}
          <span className="display-3 fw-bold text-uppercase" style={{ color: "black" }}>
  Rendez-vous
</span>

        </h3>

        <Form className="oxygen apt-form">
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Votre nom"
                className="form-control-dark"
              />
            </Col>
            <Col>
              <Form.Control
                type="tel"
                placeholder="Numéro de téléphone"
                className="form-control-dark"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Control
                type="email"
                placeholder="Email"
                className="form-control-dark"
              />
            </Col>
            <Col>
              <Form.Control
                type="date"
                placeholder="Date"
                className="form-control-dark"
              />
            </Col>
          </Row>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Message"
            className="mb-4 form-control-dark"
          />

          <Button variant="outline-warning" size="lg">
            Soumettre
          </Button>
        </Form>
      </Container>
    </div>
  );
};

export default Appointment;
