import { Button, Carousel } from "react-bootstrap";
import "./Hero.css";

function HeroSection() {
  return (
    <Carousel className="hero oxygen">
      <Carousel.Item className="caro-container">
        <img
          className="d-block w-100 caro-img"
          src="https://images.unsplash.com/photo-1485230405346-71acb9518d9c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c2VjdXJpdHklMjBndWFyZHxlbnwwfDB8MHx8&auto=format&fit=crop&w=700&q=60"
          alt="Première diapositive"
        />
        <Carousel.Caption className="caro-text text-start">
          <p className="blue-color">
            Protéger ce qui compte le plus - Votre bastion de sécurité.
          </p>
          <h3 className="fs-1 mb-4 text-uppercase">
            Nous fournissons une protection
          </h3>
          <p>
            Chez Société Aigle Sécurité, nous offrons des services de sécurité
            de pointe qui vous garantissent la tranquillité d'esprit que vous
            méritez. Notre équipe d'experts est dédiée à protéger vos biens
            contre toute menace, grande ou petite.
          </p>

          <Button variant="outline-primary" className="p-3 mt-2 custom-button">
  Contactez-nous
</Button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="caro-container">
        <img
          className="d-block w-100 caro-img"
          src="https://images.unsplash.com/photo-1496368077930-c1e31b4e5b44?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fHNlY3VyaXR5JTIwZ3VhcmR8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60"
          alt="Deuxième diapositive"
        />

        <Carousel.Caption className="caro-text text-start">
          <p className="blue-color">
            Défendre votre tranquillité d'esprit grâce à des solutions de
            sécurité avancées.
          </p>
          <h3 className="fs-1 mb-4 text-uppercase">Service pour nos clients</h3>
          <p>
            Des systèmes de surveillance high-tech à la surveillance 24h/24 et
            7j/7, nous avons tout ce qu'il vous faut. N'attendez pas qu'il soit
            trop tard - protégez vos biens avec Société Aigle Sécurité dès
            aujourd'hui !
          </p>
         <Button variant="outline-primary" className="p-3 mt-2 custom-button">
  Contactez-nous
</Button>

        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroSection;
