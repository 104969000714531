import { ServicePageObj } from "../../types";
import protection from "../../assets/img/protection.jpg";
import personal from "../../assets/img/personal.jpg";
import cit from "../../assets/img/cash-in-transit.png";
import cam from "../../assets/img/scam.png";
import alarm from "../../assets/img/alarm.png";
import courier from "../../assets/img/courier.png";
import investigation from "../../assets/img/investigation.png";
import event from "../../assets/img/event.jpg";
import gps from "../../assets/img/gps.png";

const servicesList: ServicePageObj[] = [
  {
    title: "Agents de Protection",
    shortDescription:
      "À Aigle Sécurité, nous offrons une large gamme de services de sécurité avec agents, incluant des gardes statiques (agents armés et avec matraque), des agents K9, des gardes de bureau, ainsi que des patrouilles mobiles.",
    longDescription: `Les agents de protection d'Aigle Sécurité sont des professionnels formés, dédiés à la protection des personnes, des biens et des actifs. Ils offrent des solutions de sécurité sur mesure, adaptées aux besoins spécifiques de chaque client. Equipés des dernières technologies en matière de sécurité, ils sont formés pour réagir rapidement et efficacement à toute menace.

Nos agents sont rigoureusement sélectionnés et formés pour posséder les compétences nécessaires afin d'assurer une sécurité de haut niveau. Leur formation comprend le contrôle d'accès, la gestion des urgences, la gestion des foules et la résolution de conflits. Ils sont également équipés de technologies avancées de communication et de surveillance pour garantir une vigilance constante et une réaction rapide en cas d'incident.

Chez Aigle Sécurité, nous comprenons que chaque client a des besoins uniques en matière de sécurité, c'est pourquoi nous collaborons étroitement avec eux pour concevoir et mettre en œuvre des solutions personnalisées. Nos agents sont essentiels à ces solutions, garantissant la sécurité et la tranquillité d'esprit des clients.`,
    category: "protection",
    thumbnail: protection, // Ensure the image has a dark overlay in CSS
    img_urls: [
      "https://plus.unsplash.com/premium_photo-1663047796646-975442b1538b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60", // Use dark-friendly images
      "https://images.unsplash.com/photo-1566245024852-04fbf7842ce9?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60",
    ],
  },
  {
    title: "Protection Personnelle",
    shortDescription:
      "Nos agents de protection personnelle sont hautement formés et spécialement sélectionnés pour offrir une protection discrète et efficace aux diplomates, fonctionnaires et membres de leur famille.",
    longDescription: `Aigle Sécurité propose une gamme complète de services de protection personnelle pour garantir la sécurité des individus et de leurs familles. Nos agents expérimentés sont formés et équipés des dernières technologies de sécurité pour offrir des solutions de protection personnalisées adaptées aux besoins spécifiques de nos clients.

Nos services incluent la protection rapprochée, assurant la sécurité 24/7 de personnes influentes telles que des célébrités, des politiciens ou des dirigeants d'entreprise. Nous offrons également des services de protection exécutive pour les dirigeants et leurs familles qui nécessitent une protection professionnelle et discrète.

En outre, nous proposons des services de protection résidentielle pour sécuriser les maisons et les propriétés contre des menaces telles que le vol, le vandalisme ou les intrusions non autorisées. Nos agents sont formés pour détecter et réagir à toute menace potentielle, assurant ainsi une tranquillité d'esprit à nos clients.

Chez Aigle Sécurité, nous comprenons que la protection personnelle est une question sérieuse, et nous nous engageons à fournir à nos clients le plus haut niveau de sécurité possible. Notre équipe travaille étroitement avec chaque client pour développer des plans de protection sur mesure qui répondent à leurs besoins spécifiques.`,
    category: "protection",
    thumbnail: personal, // Suggest adding a CSS overlay for dark mode
    img_urls: [
      "https://plus.unsplash.com/premium_photo-1663047796646-975442b1538b?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60",
      "https://images.unsplash.com/photo-1566245024852-04fbf7842ce9?ixlib=rb-4.0.3&auto=format&fit=crop&w=800&q=60",
    ],
  },
  {
    title: "Sécurité Événementielle",
    shortDescription:
      "Nous proposons des services de sécurité pour des événements, concerts et rassemblements, garantissant la sécurité des participants.",
    longDescription: `Aigle Sécurité fournit des services de sécurité événementielle sur mesure pour garantir la sécurité des invités, des organisateurs et des participants lors d'événements tels que des concerts, des conférences, des rassemblements et des événements d'entreprise. Nos agents de sécurité sont formés pour gérer tous les aspects de la sécurité événementielle, y compris la gestion des foules, la surveillance, le contrôle des accès et la gestion des situations d'urgence.

Nous offrons des solutions flexibles et évolutives pour répondre aux besoins spécifiques de chaque événement, en nous assurant que la sécurité ne soit jamais compromise. Nos services incluent également l'escorte sécurisée des invités VIP, l'installation de systèmes de surveillance et d'alarme, ainsi que la coordination avec les autorités locales pour assurer un environnement sûr.

Qu'il s'agisse d'un petit événement ou d'un grand rassemblement, Aigle Sécurité est prête à fournir des solutions efficaces et professionnelles pour garantir la sécurité et la tranquillité d'esprit des organisateurs et des participants.`,
    category: "event",
    thumbnail: event, // Apply dark-friendly enhancements
    img_urls: [
      "https://images.unsplash.com/photo-1597978610632-d31efc6a82b9?crop=entropy&fit=crop&w=800&q=80",
      "https://images.unsplash.com/photo-1585325283129-35fd342cde9b?crop=entropy&fit=crop&w=800&q=80",
    ],
  },
];

export default servicesList;
