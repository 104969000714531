import React, { ChangeEvent, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import "./JobApplication.css";
import { ResumeData } from "../../../types";

function JobApplicationForm() {
  const [formData, setFormData] = useState<ResumeData>({
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    phoneNumber: "",
    address: "",
    idType: "",
    idNumber: "",
    gender: "",
    resume: null,
  });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleIdTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      idType: value,
    }));
  };

  const handleResumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      setFormData((prevState) => ({
        ...prevState,
        resume: file,
      }));
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    console.log(formData); // remplacer par votre propre logique de soumission
  };

  return (
    <div className="job-application-container">
    <h1 style={{ color: "white" }} className="text-center mb-2">
  Formulaire de candidature
</h1>
    <p className="text-center">
        Merci pour votre intérêt envers la Société Aigle Sécurité. Veuillez
        remplir le formulaire ci-dessous et attendre une réponse de notre
        équipe. Nous vous contacterons prochainement.
      </p>
      <Form
        onSubmit={handleSubmit}
        className="job-application-form p-3"
        encType="multipart/form-data"
      >
        <Row className="my-3">
          <Col>
            <Form.Group controlId="formFirstName">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                placeholder="Entrez votre prénom"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formMiddleName">
              <Form.Label>Deuxième prénom</Form.Label>
              <Form.Control
                type="text"
                name="middleName"
                value={formData.middleName}
                onChange={handleInputChange}
                placeholder="Entrez votre deuxième prénom"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastName">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                placeholder="Entrez votre nom"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Entrez votre email"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Numéro de téléphone</Form.Label>
              <Form.Control
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Entrez votre numéro de téléphone"
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formAddress" className="mb-3">
          <Form.Label>Adresse</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            placeholder="Entrez votre adresse"
          />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formIdType">
              <Form.Label>Type de pièce d'identité</Form.Label>
              <Form.Control
                as="select"
                name="idType"
                value={formData.idType}
                onChange={handleIdTypeChange}
              >
                <option value="">Sélectionnez un type</option>
                <option value="ID">Carte d'identité</option>
                <option value="Passport">Passeport</option>
                <option value="Drivers permit">Permis de conduire</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formIdNumber">
              <Form.Label>Numéro de pièce</Form.Label>
              <Form.Control
                type="text"
                name="idNumber"
                value={formData.idNumber}
                onChange={handleInputChange}
                placeholder="Entrez votre numéro de pièce"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Form.Group controlId="formGender">
              <Form.Label>Sexe</Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
              >
                <option value="">Sélectionnez un sexe</option>
                <option value="Male">Homme</option>
                <option value="Female">Femme</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formResume">
              <Form.Label>CV</Form.Label>
              <Form.Control
                type="file"
                name="resume"
                onChange={handleResumeChange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          Soumettre
        </Button>
      </Form>
    </div>
  );
}

export default JobApplicationForm;
