import React from "react";
import { Col, Row } from "react-bootstrap";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiFillPhone, AiOutlineMail } from "react-icons/ai";
import "./Footer.css";

const FooterContactInfo = () => {
  return (
    <div className="contact-info">
      <Row>
        {/* Address Section */}
        <Col md={4} sm={12} xs={12} className="footer-col">
          <span className="icon-container">
            <HiOutlineLocationMarker size={25} color="black" />
          </span>
          <h4 className="mt-3">Addresse</h4>
          <p className="mt-2">
           N1- Route Remada Km7 Tataouine Tunisie
            <br></br>
            <br />
           N2- Route Mateur Km7 Manouba Tunisie
          </p>
        </Col>

        {/* Phone Section */}
        <Col md={4} sm={12} xs={12} className="footer-col">
          <span className="icon-container">
            <AiFillPhone size={25} color="black" />
          </span>
          <h4 className="mt-3">Phone</h4>
          <p className="mt-2">
            Fix : +216 75 851 990
            <br></br>
            Mobile : +216 99 952 000
            <br></br>  
            
            <br />
            Fix : +216 71 601 401
            <br></br>
            Mobile : +216 98 750 850 
          </p>
        </Col>

        {/* Email Section */}
        <Col md={4} sm={12} xs={12} className="footer-col">
          <span className="icon-container">
            <AiOutlineMail size={25} color="black" />
          </span>
          <h4 className="mt-3">Email</h4>
          <p className="mt-2">
        
            <br />
            contact@aiglesecurite.tn
            <br />
            comercial@aigledesecurite.tn
          </p>
          <p className="mt-2">
            <a
              href="http://www.sasecurite.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "none" }}
            >
            </a>
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default FooterContactInfo;
