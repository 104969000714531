import { DisplayServiceType } from "../../../types";
import Netimg from "./nettoyage.jpg";

export const serviceObjList: DisplayServiceType[] = [
  {
    title: "Service De Nettoyage",
    description:
      "Garantir un environnement propre et hygiénique est essentiel pour le bien-être de vos employés, clients et visiteurs. Aigle Sécurité propose des services de nettoyage professionnels et sur mesure pour répondre aux besoins spécifiques de chaque entreprise. Nos équipes qualifiées utilisent des équipements modernes et des produits respectueux de l'environnement pour assurer un nettoyage efficace et minutieux de vos locaux.",
    img_url: Netimg, // Ensure the image doesn't clash with dark mode; apply CSS overlays where needed.
  },
  {
    title: "Services de Protection",
    description:
      "Garantir la sécurité de votre entreprise, de vos employés et de vos clients est une priorité absolue. Nos services de protection offrent une solution complète pour protéger vos biens et propriétés contre les menaces potentielles. Nos professionnels de la sécurité, hautement qualifiés et expérimentés, utilisent les dernières technologies et les meilleures pratiques du secteur pour proposer une gamme de services, notamment des agents de sécurité armés et non armés, des patrouilles mobiles et des systèmes de contrôle d'accès.",
    img_url:
      "https://images.unsplash.com/photo-1618371731836-2b9bff9ac72a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2VjdXJpdHklMjBndWFyZHxlbnwwfDB8MHx8&auto=format&fit=crop&w=800&q=60",
  },
];
